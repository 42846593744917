import axios from 'axios';
import config from '../config';

const fetchPlayerConfig = async (configId, sanitizedCamId) => {
  let playerConfig = {};
  let camDetailsData = {};
  if (configId) {
    /* fetch config options from url hash */
    const fetchedConfig = await axios.get(`${config.camCdnUrl}/cam-config/${configId}.json`);
    playerConfig = fetchedConfig.data;
    /* match camId to id in config response or throw err */
    const configCamId = playerConfig.camId;
    if (!configCamId || configCamId !== sanitizedCamId) {
      throw new Error('Invalid player configuration');
    }
    const { data } =
      await axios.get(`${config.camCdnUrl}/cam-details/${playerConfig.camId}.json`);
    camDetailsData = data;
  } else {
    /* default camId to url param if no optional config settings */
    const { data } = await axios.get(`${config.camCdnUrl}/cam-details/${sanitizedCamId}.json`);
    camDetailsData = data;
  }

  return {
    playerConfig,
    camDetailsData,
  }
}

export default fetchPlayerConfig;
