import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import throttle from 'lodash.throttle';
import AdControls from '../AdControls';
import PlayerError from '../PlayerError';
import PlayerMessage from '../PlayerMessage';
import PlayerTimeout from '../PlayerTimeout';
import PlayerUpsell from '../PlayerUpsell';
import PremiumOverlay from '../PremiumOverlay';
import StartControls from '../StartControls';
import getAdTagUrl from '../../utils/getAdTagUrl';
import fetchPlayerConfig from '../../utils/fetchPlayerConfig';
import { trackPageEvent, trackEvent } from '../../utils/segmentEvents';
import SurflineLogo from './surfline.png';
import config from '../../config';
import './CamPlayer.scss';

class CamPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      camDetails: {
        cam: {
          streamUrl: null,
          stillUrl: null,
          isDown: {
            status: false,
            message: null
          },
          isPremium: false,
          isPrerecorded: false,
        },
        spot: {
          id: null,
          name: null,
        }
      },
      configOptions: {
        adTarget: null,
        camId: null,
        createdAt: null,
        customMessage: {
          text: null,
          href: null,
        },
        enablePremiumCam: false,
        event: null,
        partner: null,
        playerTimeout: 600,
        showPreRollAds: true,
        showMidRollAds: true,
        showMidRollCTA: true,
        autoplay: false,
      },
      player: {
        android: null,
        hls: null,
        ios: null,
        native: null,
        playerError: {
          message: null,
          status: null,
        },
        playerInitialized: false,
        secondsPlayed: 0,
        showUpSell: false,
        timeoutReached: false,
        height: null,
        width: null,
        volume: .05,
      },
      ad: {
        adCount: 0,
        adError: null,
        adActive: null,
        adIsMuted: false,
        adIsPlaying: true,
        adsPlayed: 0,
        imaUnavailable: false,
      },
    }
    this.adDisplayContainerInitialized = false;
    this.upsellTimer = null;
    this.streamingExtensions = /\.(m3u8)($|\?)/i;
    this.playerRef = new createRef(); // eslint-disable-line new-cap
    this.playerWrapperRef = new createRef(); // eslint-disable-line new-cap
    this.adPlayerRef = new createRef(); // eslint-disable-line new-cap
    this.MIDROLL_TIMEOUT = 30;
    this.UPSELL_TIMEOUT = 15;
  }

  async componentDidMount() {
    this.isIOS = typeof navigator !== 'undefined'
    && /iPad|iPhone|iPod/.test(navigator.userAgent)
    && !window.MSStream;
    this.isAndroid = navigator.userAgent.match(/Android/i);

    await this.getCamConfig();

    const { match: { params: { camId } } } = this.props;
    const { configOptions: { showPreRollAds, showMidRollAds, autoplay }, player } = this.state;
    const showAds = showPreRollAds || showMidRollAds;
    const autoplayConfig = !(this.isAndroid || this.isIOS) && autoplay;
    const controls = ['play', 'mute', 'volume', 'fullscreen'];

    if (this.playerRef.current) {
      /* create HLS instance */
      this.hls = new window.Hls();

      /* create plyr wrapper */
      this.plyr = new window.Plyr(this.playerRef.current, {
        autoplay: autoplayConfig,
        controls,
        fullscreen: { iosNative: true },
        hideControls: false,
        muted: autoplayConfig,
      });
      this.plyr.on('ready', async () => this.onPlayerReady());
      this.plyr.on('timeupdate', () => this.onPlayerTimeUpdate());
      this.plyr.on('error', err => this.onPlayerError(err));

      /* setup ads loader */
      if (showAds) {
        if (!!window.google && !!window.google.ima) {
          this.setUpAdsLoader();
        } else {
          this.setState({
            player: {
              ...player,
              playerError: {
                message: 'Error playing video. Please ensure Ad Blocker is disabled and try again',
              },
            },
          });
        }
      }
    }
    /* resize listener for player resize */
    window.addEventListener('resize', this.resizeListener);

    /* segment page event */
    trackPageEvent(camId);
  }

  componentDidUpdate(_, prevState) {
    const {
      camDetails,
      configOptions: { showPreRollAds, showMidRollAds },
      player: { hls, playerHeight, playerWidth },
    } = this.state;
    const { cam: { streamUrl, stillUrl } } = camDetails;
    if (prevState.camDetails.cam.streamUrl !== streamUrl && this.playerRef.current) {
      /* request ads */
      const showAds = showPreRollAds || showMidRollAds;
      if (showAds && !!window.google && !!window.google.ima) this.requestAds();
      /* check file extension is m3u8 to determine if we should attach hls */
      const streamingUrl = this.streamingExtensions.test(streamUrl);
      if (!hls || this.isIOS || !streamingUrl) {
        /* manually set url if hls is not supported */
        this.playerRef.current.load();
        this.playerRef.current.poster = stillUrl;
        this.playerRef.current.src = streamUrl;
      } else {
        /* attach hls if supported */
        this.playerRef.current.poster = stillUrl;
        this.hls.loadSource(streamUrl);
        this.hls.attachMedia(this.playerRef.current);
      }
    }

    /* resize ad if cam player dimensions update */
    if (prevState.player.playerWidth !== playerWidth
      || prevState.player.playerHeight !== playerHeight) {
      if (this.adsManager) {
        this.adsManager.resize(playerWidth, playerHeight, window.google.ima.ViewMode.NORMAL);
      }
    }
  }

  componentWillUnmount() {
    /* destroy all the things */
    if (this.upsellTimer) clearTimeout(this.upsellTimer);
    if (this.hls) this.hls.destroy();
    if (this.adsManager) this.adsManager.destroy();
    if (this.plyr) this.plyr.destroy();
    window.removeEventListener('resize', this.resizeListener);
  }

  /* begin player controls */
  onPlayerReady = async () => {
    const { match: { params: { camId, configId } } } = this.props;
    const { camDetails, configOptions, player } = this.state;
    const [sanitizedCamId] = camId.split('.html');
    try {
      const { camDetailsData, playerConfig } = await fetchPlayerConfig(configId, sanitizedCamId);
      if (this.playerRef.current) {
        const { height, width } = this.playerRef.current.getBoundingClientRect();
        if (camDetailsData) {
          this.setState({
            camDetails: { ...camDetails, ...camDetailsData },
            configOptions: { ...configOptions, ...playerConfig },
            player: {
              ...player,
              hls: !!window.Hls && !!window.Hls.isSupported(),
              playerHeight: height,
              playerWidth: width,
            },
          });
        } else {
          this.setState({
            player: { ...player, playerError: { message: 'No cam found for the requested id' } },
          });
        }
      }
    } catch (playerError) {
      this.setState({ player: { ...player, playerError } });
      // TODO: log err
      console.log(playerError);
    }
  }

  onPlayerTimeUpdate = () => {
    const {
      ad: { adActive },
      configOptions: { playerTimeout, showMidRollAds },
      player,
    } = this.state;
    const { secondsPlayed, showUpSell } = player;
    const currSeconds = Math.floor(this.plyr.currentTime);
    if (secondsPlayed % this.MIDROLL_TIMEOUT === 0 || secondsPlayed % playerTimeout === 0) {
      this.setState({ player: { ...player, secondsPlayed: currSeconds } });
    } else if (currSeconds > 0 && currSeconds % playerTimeout === 0) {
      this.triggerTimeout();
    } else if (showMidRollAds && !adActive && !showUpSell && currSeconds > 0 && currSeconds % this.MIDROLL_TIMEOUT === 0) {
      this.playMidRollAds();
    } else {
      this.setState({ player: { ...player, secondsPlayed: currSeconds } });
    }
  }

  onPlayerError = (playerError) => {
    const { player } = this.state;
    this.setState({ player: { ...player, playerError } });
    // TODO: log err
    console.log(playerError);
  }

  playAds = () => {
    const { ad, player } = this.state;
    const { adIsMuted } = ad;
    const { playerInitialized, volume } = player;
    /* reset player state */
    if (this.plyr.playing) this.plyr.pause();
    /* initialize the ad container via a user action */
    if (!this.adDisplayContainerInitialized) {
      this.adDisplayContainer.initialize();
      this.adDisplayContainerInitialized = true;
    }
    try {
      /* initialize the ads manager and start ad playback */
      if (!playerInitialized) {
        this.setState({ player: { ...player, playerInitialized: true } });
      }
      const { height, width } = this.playerWrapperRef.current.getBoundingClientRect();
      this.adsManager.init(width, height, window.google.ima.ViewMode.NORMAL);
      this.adsManager.setVolume(adIsMuted ? 0 : volume);
      this.adsManager.start();
    } catch (adError) {
      this.setState({ ad: { ...ad, adError } });
      this.plyr.play();
      // TODO: log err
      console.log(adError);
    }
  }

  startPlayer = (evt, clickedContinueWatching = null) => {
    const {
      configOptions: { showPreRollAds, showMidRollAds },
      player,
    } = this.state;
    const { playerInitialized } = player;
    /* preventDefault ensures onClick and onTouchEnd do not conflict */
    if (evt) evt.preventDefault();
    if (showPreRollAds && !!window.google) {
      this.playAds();
    } else if (showMidRollAds && playerInitialized && !clickedContinueWatching && !!window.google) {
      // handle case where pre-roll is off but mid-roll is on
      this.playAds();
    } else {
      if (!playerInitialized) {
        this.setState({ player: { ...player, playerInitialized: true } });
      }
      this.plyr.play();
    }
  }

  triggerTimeout = () => {
    const { player } = this.state;
    this.plyr.pause();
    this.setState({
      player: { ...player, timeoutReached: true, secondsPlayed: 0 },
    });
  }

  continueWatching = (evt) => {
    if (evt) evt.preventDefault();
    this.setState(prevState => ({
      ad: { ...prevState.ad, adsPlayed: 0 },
      player: { ...prevState.player, timeoutReached: false },
    }));
    this.startPlayer(null, true);
  }

  // eslint-disable-next-line react/sort-comp
  resizeListener = throttle(() => {
    const { player } = this.state;
    const { playerHeight, playerWidth } = player;
    if (this.playerRef.current) {
      const { height, width } = this.playerRef.current.getBoundingClientRect();
      if (height !== playerHeight || width !== playerWidth) {
        this.setState({ player: { ...player, playerHeight: height, playerWidth: width } });
      }
    }
  }, 350);
  /* end player controls */

  /* begin ad configuration */
  setUpAdsLoader = () => {
    /* attach ad container to ima */
    this.adDisplayContainer = new window.google.ima.AdDisplayContainer(
      this.adPlayerRef.current,
      this.playerRef.current,
    );
    /* setup ads loader once per page */
    this.adsLoader = new window.google.ima.AdsLoader(this.adDisplayContainer);
    this.adsLoader.addEventListener(
      window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      this.onAdsManagerLoaded,
      false,
    );
    this.adsLoader.addEventListener(
      window.google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError,
      false,
    );
  }

  requestAds = (useDefaultAdTag) => {
    /* build and request ad config */
    if (this.adsManager) this.adsManager.destroy();
    if (this.adsLoader) this.adsLoader.contentComplete();
    const adsRequest = new window.google.ima.AdsRequest();
    const { height, width } = this.playerWrapperRef.current.getBoundingClientRect();
    const {
      camDetails: { spot },
      configOptions: { adTarget, camId },
      ad: { adCount },
    } = this.state;
    adsRequest.adTagUrl = getAdTagUrl(
      adTarget,
      adCount,
      spot,
      camId,
      width,
      height,
      useDefaultAdTag,
    );
    adsRequest.linearAdSlotWidth = width;
    adsRequest.linearAdSlotHeight = height;
    this.adsLoader.requestAds(adsRequest);
  }

  onAdsManagerLoaded = (adsManagerLoadedEvent) => {
    this.adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
    /* display ad countdown and attribution */
    this.adsRenderingSettings.uiElements = [
      window.google.ima.UiElements.COUNTDOWN,
      window.google.ima.UiElements.AD_ATTRIBUTION,
    ];
    this.adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
    /* get the ads manager */
    this.adsManager = adsManagerLoadedEvent.getAdsManager(
      this.playerRef.current,
      this.adsRenderingSettings,
    );
    /* add listeners to the required events */
    this.adsManager.addEventListener(
      window.google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError,
    );
    this.adsManager.addEventListener(
      window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
      this.onContentPauseRequested,
    );
    this.adsManager.addEventListener(
      window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
      this.onContentResumeRequested,
    );
    this.adsManager.addEventListener(
      window.google.ima.AdEvent.Type.LOADED,
      this.onAdLoaded,
    );
  }

  onAdLoaded = (adEvent) => {
    /* play stream if overlay ad is returned */
    const ad = adEvent.getAd();
    if (!ad.isLinear()) this.plyr.play();
  }

  onContentPauseRequested = () => {
    /* set adActive in state to prevent infinite mobile pre-roll and reset adCount */
    const { ad } = this.state;
    this.setState({ ad: { ...ad, adActive: true, } });
    this.plyr.pause();
  }

   onContentResumeRequested = () => {
    /* start player, toggle midroll flag, and restart progress timer */
    const { ad, player } = this.state;
    this.setState({
      ad: { ...ad, adActive: false, adCount: 1 },
      player: { ...player, secondsPlayed: 0 },
    });
    this.plyr.play();
    /* request next ads immediately */
    this.requestAds();
  }

  playMidRollAds = () => {
    const { configOptions: { showMidRollCTA } } = this.state;
    /* stop player to prevent currentTime from incrementing */
    if (this.plyr.playing) this.plyr.pause();
    /* exit fullscreen to display cta */
    if (this.plyr.fullscreen && this.plyr.fullscreen.active) this.plyr.fullscreen.exit();
    if (this.isIOS && this.playerRef.current.webkitDisplayingFullscreen) {
      this.playerRef.current.webkitExitFullScreen();
    }

    if (showMidRollCTA) {
      /* play midroll with CTA: reset secondsPlayed counter and toggle showUpSell flags */
      this.setState(prevState => ({
        player: { ...prevState.player, secondsPlayed: 0, showUpSell: true },
      }));
      /* display upsell before triggering midroll */
      this.upsellTimer = setTimeout(() => {
        /* incrememt ad count, toggle adActive and showUpSell flags */
        this.setState(prevState => ({
          ad: { ...prevState.ad, adActive: true, adsPlayed: prevState.ad.adsPlayed + 1 },
          player: { ...prevState.player, secondsPlayed: 0, showUpSell: false },
        }));
        /* trigger midroll */
        this.startPlayer();
      }, this.UPSELL_TIMEOUT * 1000);
    } else {
      /* play midroll without CTA: incrememt ad count, toggle adActive flag */
      this.setState(prevState => ({
        ad: { ...prevState.ad, adActive: true, adsPlayed: prevState.ad.adsPlayed + 1 },
        player: { ...prevState.player, secondsPlayed: 0 },
      }));
      /* trigger midroll */
      this.startPlayer();
    }
  }

  onAdError = (adErrorEvent) => {
    /* handle the retires, error logging, and destroy the AdsManager */
    const { ad } = this.state;
    const { adCount } = ad;
    this.setState({ ad: { ...ad, adError: adErrorEvent, adCount: adCount + 1 } });
    if (adCount === 5) {
      const useDefaultAdTag = true;
      this.requestAds(useDefaultAdTag);
    } else if (adCount < 5) {
      this.requestAds();
    } else {
      /* if an ad fails to load more than 5 times, play the camera and reset adCount */
      this.plyr.play();
      this.setState({ ad: { ...ad, adCount: 1 } });
      if (this.adsManager) this.adsManager.destroy();
    }
  }

  handleAdPlayPauseClick = (evt) => {
    if (evt) evt.preventDefault();
    const { ad } = this.state;
    const { adIsPlaying } = ad;
    if (adIsPlaying) {
      this.setState({ ad: { ...ad, adIsPlaying: false } });
      if (this.adsManager) this.adsManager.pause();
    } else {
      this.setState({ ad: { ...ad, adIsPlaying: true } });
      if (this.adsManager) this.adsManager.resume();
    }
  }

  handleAdMuteClick = (evt) => {
    if (evt) evt.preventDefault();
    const { ad, player: { volume } } = this.state;
    const { adIsMuted } = ad;
    if (adIsMuted) {
      this.setState({ ad: { ...ad, adIsMuted: false } });
      if (this.adsManager) this.adsManager.setVolume(volume);
    } else {
      this.setState({ ad: { ...ad, adIsMuted: true } });
      if (this.adsManager) this.adsManager.setVolume(0);
    }
  }
  /* end ad configuration */

  getCamPlayerClasses = () => {
    const { ad: { adActive }, player: { showUpSell, timeoutReached } } = this.state;
    return classnames({
      'sl-cam-player__player': true,
      'sl-cam-player__player__controls': !adActive && !showUpSell &&
        this.plyr && !timeoutReached,
    });
  }

  getCamConfig = async () => {
    const { match: { params: { camId, configId } } } = this.props;
    const { configOptions } = this.state;
    const [sanitizedCamId] = camId.split('.html');
    const { playerConfig } = await fetchPlayerConfig(configId, sanitizedCamId);
    if (playerConfig) {
      this.setState({
        configOptions: { ...configOptions, ...playerConfig },
      });
    }
  }

  render() {
    const { match: { params: { camId } } } = this.props;
    const {
      ad: { adActive, adIsMuted, adIsPlaying },
      camDetails: {
        cam: { isPremium, isDown, stillUrl },
        spot,
      },
      configOptions: { customMessage, enablePremiumCam },
      player: { playerError, playerInitialized, showUpSell, timeoutReached },
    } = this.state;

    /* display premium overlay */
    if (isPremium && !enablePremiumCam) {
      return (
        <PremiumOverlay
          camId={camId}
          stillUrl={stillUrl}
          spot={spot}
          customMessage={customMessage}
        />
      );
    }

    /* display error message */
    if ((isDown && isDown.status) || (playerError && playerError.message)) {
      let message = config.errorMessage;
      if (playerError && playerError.message) message = playerError.message;
      if (isDown && isDown.status && isDown.message) message = isDown.message;
      return (
        <PlayerError
          camId={camId}
          stillUrl={stillUrl}
          message={message}
          spot={spot}
          customMessage={customMessage}
        />
      );
    }

    return (
      <div className="sl-cam-player">
        <div className='sl-cam-player__outer-wrapper'>
          <div className='sl-cam-player__wrapper' ref={this.playerWrapperRef}>
            <div className={this.getCamPlayerClasses()}>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video id="sl-cam-player" controls playsInline ref={this.playerRef} />
            </div>
            <div className="sl-cam-player__ad-container">
              <div id="sl-adContainer" ref={this.adPlayerRef} />
            </div>
            {adActive ? (
              <AdControls
                adIsMuted={adIsMuted}
                adIsPlaying={adIsPlaying}
                onPlayPauseHandler={evt => this.handleAdPlayPauseClick(evt)}
                onMuteHandler={evt => this.handleAdMuteClick(evt)}
              />
            ) : null}
          </div>
          {(!playerInitialized && !timeoutReached && this.plyr && !this.plyr.playing) ? (
            <StartControls onClickHandler={(evt) => this.startPlayer(evt)} />
          ) : null}
          {showUpSell ? (
            <PlayerUpsell stillUrl={stillUrl} upsellTimeout={this.UPSELL_TIMEOUT} camId={camId} />
          ) : null}
          {timeoutReached ? (
            <PlayerTimeout
              onClickHandler={(evt) => this.continueWatching(evt)}
              stillUrl={stillUrl}
              camId={camId}
            />
          ) : null}
          {!playerInitialized || !adActive ? (
            <a
              className="sl-cam-player__player-logo"
              href={config.surflineUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => trackEvent('Clicked Link', {
                linkLocation: 'logo',
                linkUrl: config.surflineUrl,
                camId,
              })}
            >
              <img src={SurflineLogo} alt="Surfline" />
            </a>
          ) : null}
        </div>
        <PlayerMessage customMessage={customMessage} spot={spot} camId={camId} />
      </div>
    );
  }
}

CamPlayer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      camId: PropTypes.string,
      configId: PropTypes.string,
    }),
  }),
}

CamPlayer.defaultProps = {
  match: {
    params: {
      camId: null,
      configId: null,
    },
  },
}

export default CamPlayer;
