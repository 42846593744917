import React from 'react';
import PropTypes from 'prop-types';
import MuteButton from '../MuteButton';
import PlayPause from '../PlayPause';
import './AdControls.scss';

const AdControls = ({
  adIsMuted,
  adIsPlaying,
  onPlayPauseHandler,
  onMuteHandler,
}) => (
  <div className="sl-cam-player__ad-controls">
    <PlayPause
      isPlaying={adIsPlaying}
      onClickHandler={onPlayPauseHandler}
    />
    <MuteButton
      isMuted={adIsMuted}
      onClickHandler={onMuteHandler}
    />
  </div>
);

AdControls.propTypes = {
  adIsMuted: PropTypes.bool.isRequired,
  adIsPlaying: PropTypes.bool.isRequired,
  onPlayPauseHandler: PropTypes.func.isRequired,
  onMuteHandler: PropTypes.func.isRequired,
}

export default AdControls;
