import React from 'react';
import PropTypes from 'prop-types';
import './MuteButton.scss';

const MuteButton = ({ isMuted, onClickHandler }) => (
  <svg
    className="sl-mute-button"
    onClick={onClickHandler}
    onTouchEnd={onClickHandler}
    viewBox="0 0 22 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    {isMuted ? (
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          className="sl-mute-button__shape"
          d={`M0.930769231,3.94642916 L4.31538462,3.94642916 L10.5346154,0.136295241 C11.1269231,
            -0.24471815 11.9307692,0.220964884 11.9307692,0.940656845 L11.9307692,17.0702238 
            C11.9307692,17.8322505 11.0423077,18.2555987 10.45,17.8322505 L4.31538462,14.0644514 
            L0.930769231,14.0644514 C0.423076923,14.0644514 1.77635684e-15,13.6411032 1.77635684e-15,
            13.1330854 L1.77635684e-15,4.87779522 C1.77635684e-15,4.36977737 0.423076923,3.94642916 
            0.930769231,3.94642916 Z M20.4346154,5.59748719 C20.8153846,5.25880862 21.3653846,
            5.25880862 21.7461538,5.59748719 C22.0846154,5.97850058 22.0846154,6.52885325 21.7461538,
            6.90986665 L19.6307692,9.02660771 L21.7461538,11.101014 C22.0846154,11.4820273 22.0846154,
            12.03238 21.7461538,12.4133934 C21.3653846,12.752072 20.8153846,12.752072 20.4346154,
            12.4133934 L18.3192308,10.2966523 L16.2461538,12.4133934 C15.8653846,12.752072 15.3153846,
            12.752072 14.9346154,12.4133934 C14.5961538,12.03238 14.5961538,11.4820273 14.9346154,
            11.101014 L17.05,9.02660771 L14.9346154,6.90986665 C14.5961538,6.52885325 14.5961538,
            5.97850058 14.9346154,5.59748719 C15.2730769,5.25880862 15.8653846,5.25880862 16.2461538,
            5.59748719 L18.3192308,7.71422825 L20.4346154,5.59748719 Z M5.5,5.38581308 L5.5,12.6250675 
            L10.0692308,15.4615005 L10.0692308,2.54938005 L5.5,5.38581308 Z M3.68076923,5.80916129 
            L1.81923077,5.80916129 L1.81923077,12.2017193 L3.68076923,12.2017193 L3.68076923,
            5.80916129 Z`}
          fillRule="nonzero"
        />
      </g>
    ) : (
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g className="sl-mute-button__shape">
          <path
            d={`M0.930769231,3.94642916 L4.31538462,3.94642916 L10.5346154,0.136295241 
              C11.1269231,-0.24471815 11.9307692,0.220964884 11.9307692,0.940656845 L11.9307692,
              17.0702238 C11.9307692,17.8322505 11.0423077,18.2555987 10.45,17.8322505 L4.31538462,
              14.0644514 L0.930769231,14.0644514 C0.423076923,14.0644514 4.88498131e-14,13.6411032 
              4.88498131e-14,13.1330854 L4.88498131e-14,4.87779522 C4.88498131e-14,4.36977737 
              0.423076923,3.94642916 0.930769231,3.94642916 Z M5.5,5.38581308 L5.5,12.6250675 
              L10.0692308,15.4615005 L10.0692308,2.54938005 L5.5,5.38581308 Z M3.68076923,
              5.80916129 L1.81923077,5.80916129 L1.81923077,12.2017193 L3.68076923,12.2017193 
              L3.68076923,5.80916129 Z`}
            fillRule="nonzero"
          />
          <rect x="15" y="8" width="6.5" height="2" rx="1" />
          <rect
            transform="translate(16.750000, 4.000000) rotate(-33.000000) translate(-16.750000, -4.000000) "
            x="13.5"
            y="3"
            width="6.5"
            height="2"
            rx="1"
          />
          <rect
            transform={`translate(16.750000, 14.000000) scale(1, -1) rotate(-33.000000) 
              translate(-16.750000, -14.000000) `}
            x="13.5"
            y="13"
            width="6.5"
            height="2"
            rx="1"
          />
        </g>
      </g>
    )}
  </svg>
);

MuteButton.propTypes = {
  isMuted: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}

export default MuteButton;
