import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PlayerMessage from '../PlayerMessage';
import './PlayerOverlay.scss';

const getPlayerOverlayClasses = innerOverlay => classnames({
  'sl-cam-player-overlay': true,
  'sl-cam-player-overlay--inner': innerOverlay,
})

const PlayerOverlay = ({ children, stillUrl, innerOverlay, camId }) => (
  <div className={getPlayerOverlayClasses(innerOverlay)}>
    <div
      className="sl-cam-player-overlay__wrapper"
      style={{ backgroundImage: `url(${stillUrl || ''})` }}
    >
      <div className="sl-cam-player-overlay__content">
        {children}
      </div>
    </div>
    {!innerOverlay ? <PlayerMessage camId={camId} /> : null}
  </div>
);

PlayerOverlay.propTypes = {
  camId: PropTypes.string,
  children: PropTypes.node.isRequired,
  innerOverlay: PropTypes.bool,
  stillUrl: PropTypes.string,
}

PlayerOverlay.defaultProps = {
  camId: null,
  innerOverlay: false,
  stillUrl: null,
}

export default PlayerOverlay;
