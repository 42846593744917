import config from '../config';

const getAdTagUrl = (
  adTarget,
  adCount,
  spot,
  camId,
  width,
  height,
  useDefaultAdTag,
) => {
  if (useDefaultAdTag) {
    return `${config.productCDN}/adtags/PreRoll_SLAdFreePrem_rincon_MVP_feb18.xml`;
  }
  const adTagConfig = {
    sz: '1280x720|640x480',
    iu: '/1024858/PreRoll_Embed',
    ciu_szs: '300x250,320x50',
    impl: 's',
    gdfp_req: '1',
    env: 'vp',
    output: 'vast',
    unviewed_position_start: '1',
    correlator: Math.floor(new Date() / 1000),
  };
  /* override default ad target if custom config provides */
  if (adTarget) adTagConfig.iu = `/1024858/${adTarget}`;
  const adTagOptions = {
    ...adTagConfig,
    url: encodeURIComponent(`${window.location.protocol}//${window.location.hostname}`),
    description_url: encodeURIComponent(window.location.href),
  };
  const customOptions = {
    spotid: spot ? spot.id : null,
    viewtype: 'EMBED',
    qaFlag: null,
    embedUrl: window.location.href,
    usertype: 'free',
    count: adCount,
    width,
    height,
    camId, 
  };
  const getStringFromParams = params => Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
  const stringParams = getStringFromParams(adTagOptions);
  const customParams = encodeURIComponent(getStringFromParams(customOptions));
  return `https://pubads.g.doubleclick.net/gampad/ads?${stringParams}&cust_params=${customParams}`;
}

export default getAdTagUrl;
