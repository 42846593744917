import React from 'react';
import PropTypes from 'prop-types';
import CountDownTimer from '@surfline/quiver-react/dist/CountDownTimer';
import PlayerOverlay from '../PlayerOverlay';
import { getCTAHref, trackEvent } from '../../utils/segmentEvents';
import './PlayerUpsell.scss';

const PlayerUpsell = ({ stillUrl, upsellTimeout, camId }) => (
  <PlayerOverlay stillUrl={stillUrl} innerOverlay>
    <h2 className="sl-cam-player__player-upsell__title">
      Watch waves, not ads. Start your free trial now.
    </h2>
    <a
      className="sl-cam-player__player-upsell__cta"
      href={getCTAHref()}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => trackEvent('Clicked Subscribe CTA', { ctaLocation: 'camera embed', camId })}
    >
      Go Premium
    </a>
    <div className="sl-cam-player__player-upsell__countdown">
      <p>
        The next ad will start in
        <span><CountDownTimer countTime={upsellTimeout} /></span>
        seconds
      </p>
    </div>
  </PlayerOverlay>
);

PlayerUpsell.propTypes = {
  camId: PropTypes.string,
  stillUrl: PropTypes.string,
  upsellTimeout: PropTypes.number.isRequired,
}

PlayerUpsell.defaultProps = {
  camId: null,
  stillUrl: null,
}

export default PlayerUpsell;
