import React from 'react';
import PropTypes from 'prop-types';
import './PlayPause.scss';

const PlayPause = ({ isPlaying, onClickHandler }) => (
  <svg
    className="sl-play-pause"
    onClick={onClickHandler}
    onTouchEnd={onClickHandler}
    viewBox="0 0 13 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    {isPlaying ? (
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g className="sl-play-pause__shape">
          <rect x="0" y="0" width="5" height="14" rx="1" />
          <rect x="8" y="0" width="5" height="14" rx="1" />
        </g>
      </g>
    ) : (
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="12 7 0 14 0 0" className="sl-play-pause__shape" />
      </g>
    )}
  </svg>
);

PlayPause.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}

export default PlayPause;
