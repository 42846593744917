import React from 'react';
import './PremiumIcon.scss';

const PremiumIcon = () => (
  <svg
    className="sl-cam-player-premium-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 27"
  >
    <g fill="none" fillRule="evenodd" strokeWidth="1.152">
      <path
        className="sl-cam-player-premium-icon__flag"
        d="M.576.576v25.541L12 21.088l11.424 5.03V.575H.576z"
      />
      <path
        className="sl-cam-player-premium-icon__cam"
        d={`M18.286 8.951v5.42l-2.794-1.458v1.638c0 .392-.315.71-.704.71h-8.37a.707.707 0 0 
          1-.704-.71V8.928c0-.393.316-.71.704-.71h8.37c.389 0 .704.317.704.71v1.48l2.794-1.457z`}
      />
    </g>
  </svg>
);

export default PremiumIcon;
