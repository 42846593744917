import canUseDOM from '@surfline/web-common/dist/canUseDOM';
import config from '../config';

const getParentPage = () => {
  let parentPage = null;
  if (window.location !== window.parent.location) {
    parentPage = document.referrer;
  }
  return parentPage;
}

export const getCTAHref = () => {
  let href = `${config.surflineUrl}/upgrade`;
  const parentPage = getParentPage();
  if (parentPage) {
    const encodedParentPage = encodeURIComponent(parentPage);
    href += `?utm_source=${encodedParentPage}&utm_medium=referral&utm_campaign=cam-embed`;
  }
  return href;
}

export const trackEvent = (event, props) => {
  if (canUseDOM && window.analytics) {
    window.analytics.track(event, props);
  }
}

export const trackPageEvent = (camId) => {
  if (canUseDOM && window.analytics) {
    window.analytics.page('Camera Player Embed', { camId });
  }
}
