import React from 'react';
import PropTypes from 'prop-types';
import PlayerOverlay from '../PlayerOverlay';
import './PlayerError.scss';

const PlayerError = ({ stillUrl, message, camId }) => (
  <PlayerOverlay stillUrl={stillUrl} camId={camId}>
    <h2 className="sl-cam-player__player-error__title">{message}</h2>
  </PlayerOverlay>
);

PlayerError.propTypes = {
  camId: PropTypes.string,
  stillUrl: PropTypes.string,
  message: PropTypes.string,
}

PlayerError.defaultProps = {
  camId: null,
  stillUrl: null,
  message: null,
}

export default PlayerError;
