import { hot } from 'react-hot-loader/root';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CamPlayer from '../CamPlayer';
import './App.scss';

const App = () => (
  <Router>
    <Route path="/cams/:camId/:configId?" component={CamPlayer} />
  </Router>
);

export default hot(App);
