import React from 'react';
import PropTypes from 'prop-types';
import buildSpotUrl from '../../utils/buildSpotUrl';
import { trackEvent } from '../../utils/segmentEvents';
import config from '../../config';
import './PlayerMessage.scss';

const getPlayerMessageHref = (message, spot) => {
  let href = config.surflineUrl;
  if (message && message.href) href = message.href;
  else if (spot && spot.id) href = buildSpotUrl(spot.name, spot.id);
  return href; 
}

const PlayerMessage = ({ customMessage, spot, camId }) => {
  const playerMessageHref = getPlayerMessageHref(customMessage, spot);
  return (
    <div className="sl-cam-player__player-message">
      <a
        href={playerMessageHref}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => trackEvent('Clicked Link', {
          linkLocation: 'bottom of cam player',
          linkUrl: playerMessageHref,
          camId,
        })}
      >
        {(customMessage && customMessage.text) ?
          <p>{customMessage.text}</p>
          : (
            <p>
              Surfline HD Cam. Click here to see
              <span>{(spot && spot.name) ? ` ${spot.name} ` : ' Surfline.com '}</span>
              and all nearby cams live.
            </p>
          )}
      </a>
    </div>
  );
}

PlayerMessage.propTypes = {
  camId: PropTypes.string,
  customMessage: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
  }),
  spot: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
}

PlayerMessage.defaultProps = {
  camId: null,
  customMessage: {
    text: null,
    href: null,
  },
  spot: {
    id: null,
    name: null,
  },
}

export default PlayerMessage;
