import config from '../config';

const slugify = str => str
  .toLowerCase()
  .replace(/[^a-zA-Z0-9]/g, '-')
  .replace(/(-){2,}/g, '-');

const buildSpotUrl = (spotName, spotId) =>
  `${config.surflineUrl}/surf-report/${slugify(spotName)}/${spotId}`;

export default buildSpotUrl;
