import React from 'react';
import PropTypes from 'prop-types';
import PlayerOverlay from '../PlayerOverlay';
import './PlayerTimeout.scss';

const PlayerTimeout = ({ onClickHandler, stillUrl }) => (
  <PlayerOverlay stillUrl={stillUrl} innerOverlay>
    <h2 className="sl-cam-player__player-timeout__title">
      Are you still watching?
    </h2>
    <button
      className="sl-cam-player__player-timeout__button"
      onClick={onClickHandler}
      onTouchEnd={onClickHandler}
      type="button"
    >
      Continue Watching
    </button>
  </PlayerOverlay>
);

PlayerTimeout.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  stillUrl: PropTypes.string,
}

PlayerTimeout.defaultProps = {
  stillUrl: null,
}

export default PlayerTimeout;
