import React from 'react';
import PropTypes from 'prop-types';
import PlayerOverlay from '../PlayerOverlay';
import PremiumIcon from '../PremiumIcon';
import './PremiumOverlay.scss';
import { getCTAHref, trackEvent } from '../../utils/segmentEvents';
import config from '../../config';

const PremiumOverlay = ({ stillUrl, camId }) => (
  <PlayerOverlay stillUrl={stillUrl} camId={camId}>
    <PremiumIcon />
    <h2 className="sl-cam-player-premium-overlay__title">
      This is a High Resolution, Premium Only Cam
    </h2>
    <h5 className="sl-cam-player-premium-overlay__message">
      Watch live conditions of this spot in full HD, ad-free
    </h5>
    <a
      className="sl-cam-player-premium-overlay__cta"
      href={getCTAHref()}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => trackEvent('Clicked Subscribe CTA', { ctaLocation: 'premium overlay', camId })}
    >
      Go Premium
    </a>
    <a
      className="sl-cam-player-premium-overlay__learn-more"
      href={`${config.surflineUrl}/premium-benefits`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn more about Surfline Premium
    </a>
  </PlayerOverlay>
);

PremiumOverlay.propTypes = {
  camId: PropTypes.string,
  stillUrl: PropTypes.string,
}

PremiumOverlay.defaultProps = {
  camId: null,
  stillUrl: null,
}

export default PremiumOverlay;
