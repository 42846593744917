import React from 'react';
import PropTypes from 'prop-types';
import './StartControls.scss';

const StartControls = ({ onClickHandler }) => (
  <div className="sl-cam-player__start-controls">
    <svg
      className="sl-cam-player__start-controls__icon"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClickHandler}
      onTouchEnd={onClickHandler}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1096.000000, -568.000000)">
          <g transform="translate(1096.000000, 568.000000)">
            <g>
              <rect x="0" y="0" rx="24" />
              <polygon points="30 24 20 30 20 18" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

StartControls.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
}

export default StartControls;
